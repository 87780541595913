<template>
 <div class="page-not-found">
	<div id="notfound">
		<div class="notfound">
			<div class="notfound-404">
				<h2>404 - page not found</h2>
			</div>
			<router-link class="btn-blue shadow" to="/" >TOPへ戻る</router-link>
		</div>
	</div>
 </div>
</template>
<script lang="ts" src="@/presentation/views/pages/404/404.ts" />
<style lang="scss" src="@/presentation/views/pages/404/404.scss" scoped></style>
